import {ChangeEvent, useState} from "react";
import {useImage} from "../../utils/image-context";
import styles from "./page-signature.module.css";
import OutlookSignatureAll from '../../components/outlook/outlook-signature-all/outlook-signature-all';
import Input from "../../ui-kit/input/input";
import CustomAvatarEditor from "../../components/custom-avatar-editor/custom-avatar-editor";

// Конфигурация для всех input полей
const inputsConfig = [
  {label: 'Имя', valueKey: 'inputName', placeholder: 'Имя', type: 'text', maxLength: 20},
  {label: 'Фамилия', valueKey: 'inputFamily', placeholder: 'Фамилия', type: 'text', maxLength: 20},
  {label: 'Отчество', valueKey: 'inputPatronymic', placeholder: 'Отчество', type: 'text', maxLength: 20},
  {label: 'Телефон', valueKey: 'inputTelephoneSecond', placeholder: 'Телефон', type: 'number', maxLength: 20},
  {label: 'Доб.', valueKey: 'inputTelephoneExt', placeholder: 'Телефон добавочный', type: 'number', maxLength: 8},
  {label: 'Мобильный телефон', valueKey: 'inputTelephone', placeholder: 'Телефон', type: 'number', maxLength: 20},
  {label: 'Должность', valueKey: 'inputPosition', placeholder: 'Должность', type: 'text', maxLength: 50},
  {label: 'Город', valueKey: 'inputCity', placeholder: 'Город', type: 'text', maxLength: 40, additionalClass: styles['label--column-2']},
  {label: 'Адрес', valueKey: 'inputAddress', placeholder: 'Адрес', type: 'text', maxLength: 80, additionalClass: styles['label--column-3']},
];

const PageSignature = () => {

  const [formData, setFormData] = useState({
    inputName: '',
    inputFamily: '',
    inputPatronymic: '',
    inputPosition: '',
    inputTelephone: '',
    inputTelephoneExt: '',
    inputTelephoneSecond: '',
    inputCity: '',
    inputAddress: ''
  });

  const {imageUrl, setImageUrl} = useImage();
  const defaultImageUrl = "https://api.marketing.serconsrus.ru/wp-content/uploads/2024/08/avatar-2.png";
  const avatarUrl = imageUrl || defaultImageUrl;

  const validateInput = (
    value: string,
    type: 'text' | 'number',
    maxLength: number,
    isAddress: boolean = false,
    inputCity: boolean = false // Новый параметр
  ): string => {
    if (type === 'text') {
      if (inputCity) {
        // Для города разрешаем буквы, цифры, пробелы, дефисы, русские и английские точки, запятые
        return value.replace(/[^a-zA-Zа-яА-Я\s,-.\u002C\u002E]/g, '').slice(0, maxLength);
      }
      if (isAddress) {
        // Для адреса без города разрешаем буквы, цифры, пробелы, дефисы и запятые
        return value.replace(/[^a-zA-Zа-яА-Я0-9\s,-.\u002C\u002E]/g, '').slice(0, maxLength);
      }
      // Для других текстовых полей разрешаем буквы и пробелы
      return value.replace(/[^a-zA-Zа-яА-Я\s-]/g, '').slice(0, maxLength);
    }
  
    if (type === 'number') {
      // Для числовых полей удаляем все, кроме цифр, пробелов, скобок и дефисов
      return value.replace(/[^\d\s()-]/g, '').slice(0, maxLength);
    }
  
    return value;
  };

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    key: string,
    type: 'text' | 'number',
    maxLength: number
  ) => {
    const isAddressField = key === 'inputAddress'; // Проверяем, если это поле адреса
    const isCityField = key === 'inputCity'; // Проверяем, если это поле города
  
    const validatedValue = validateInput(
      event.target.value,
      type,
      maxLength,
      isAddressField,
      isCityField // Передаем флаг для города
    );
  
    setFormData((prevData) => ({
      ...prevData,
      [key]: validatedValue,
    }));
  };
  

  return (
    <>
      <section className={styles.signature}>
        <div className="container">
          <div className={styles.signature__widget}>

            <div className={styles.signature__title}>
              <h1>Персональные данные</h1>
              <a className={styles.signature__manual}
                 href="https://api.marketing.serconsrus.ru/wp-content/uploads/2024/10/ИНСТРУКЦИЯ_Подпись_сотрудника_в_Outlook.pdf"
                 target="_blank" rel="noopener noreferrer">инструкция</a>
            </div>

            <div className={styles.signature__row}>

              <CustomAvatarEditor/>

              <div className={styles.signature__data}>
                <div className={styles.signature__information}>
                  {inputsConfig.map(({label, valueKey, placeholder, additionalClass = '', type, maxLength}) => (
                    <Input
                      key={valueKey}
                      label={label}
                      value={formData[valueKey as keyof typeof formData]}
                      onChange={(e) => handleInputChange(e, valueKey, type as 'text' | 'number', maxLength!)}
                      placeholder={placeholder}
                      additionalClass={additionalClass}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <OutlookSignatureAll {...formData} avatarUrl={avatarUrl}/>

        </div>
      </section>
    </>
  );
};

export default PageSignature;
