import {Head} from "@react-email/components";

export function HeadCustom() {
  return (
    <>
      <Head>
        <style>
          {`
            a {
              text-decoration: none !important;
            }
          `}
        </style>
      </Head>
    </>
  )
}